import React from "react";
import type { ITask } from "../../data/task";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

type Props = {
    task: ITask;
    deleteTask: (_id: string) => void;
};

const weekday = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
const Task: React.FC<Props> = ({ task, deleteTask }) => {
    const date = new Date(task.date);
    return (
        <div className="Card--task">
            <div className="Card--text task">
                <div className="Card--habit-group">
                    <h1>{task?.habit?.name}</h1>
                    <h3>
                        <i>
                            {weekday[date.getDay()]} {date.toLocaleDateString()}
                        </i>
                    </h3>
                </div>
                <div className="Card--task-notes">
                    <h2>{task.notes}</h2>
                </div>
                <div className="Card--button">
                    <button
                        className="Card--button__delete"
                        onClick={() => deleteTask(task._id)}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Task;
