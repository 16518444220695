import React from "react";
import type { IHabit } from "../../data/habit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

type Props = {
    habit: IHabit;
    deleteHabit: (_id: string) => void;
};

const Habit: React.FC<Props> = ({ habit, deleteHabit }) => {
    return (
        <div className="Card">
            <div className="Card--text">
                <h1>{habit.name}</h1>
                <span>{habit.description}</span>
            </div>
            <div className="Card--button">
                <button
                    onClick={() => deleteHabit(habit._id)}
                    className="Card--button__delete"
                >
                    <FontAwesomeIcon icon={faX} />
                </button>
            </div>
        </div>
    );
};

export default Habit;
