import React, { useState } from "react";
import AddList from "./Add";
import List from "./List";
import {
    getTasks,
    addTask,
    deleteTask,
    ITask,
    emptyForm,
    IForm,
} from "../../data/task";
import { getHabits, IHabit } from "../../data/habit";
import { useEffectOnce } from "../../utils";

const App: React.FC = () => {
    const [tasks, setTasks] = useState<ITask[]>([]);
    const [habits, setHabits] = useState<IHabit[]>([]);
    const [formData, setFormData] = useState<IForm>(emptyForm);

    useEffectOnce(() => {
        fetchTasks();
        fetchHabits();
    });

    const fetchTasks = (): void => {
        getTasks()
            .then(({ data: { tasks } }: ITask[] | any) => setTasks(tasks))
            .catch((err: Error) => console.log(err));
    };

    const handleSaveTask = (e: React.FormEvent, formData: ITask): void => {
        e.preventDefault();
        addTask(formData)
            .then(({ status, data }) => {
                if (status !== 201) {
                    throw new Error("Error! Task not saved");
                }
                setTasks(data.tasks);
            })
            .catch((err) => console.log(err));
    };

    /*
    const handleUpdateTask = (task: ITask): void => {
        updateTask(task)
            .then(({ status, data }) => {
                if (status !== 200) {
                    throw new Error("Error! Task not updated");
                }
                setTasks(data.tasks);
            })
            .catch((err) => console.log(err));
    };
*/

    const handleDeleteTask = (_id: string): void => {
        deleteTask(_id)
            .then(({ status, data }) => {
                if (status !== 200) {
                    throw new Error("Error! Task not deleted");
                }
                setTasks(data.tasks);
            })
            .catch((err) => console.log(err));
    };

    const fetchHabits = (): void => {
        getHabits()
            .then(({ data }: IHabit[] | any) => {
                if (!formData.habit) {
                    setFormData({ ...emptyForm, habit: data.habits[0]._id });
                }
                setHabits(data.habits);
            })
            .catch((err: Error) => console.log(err));
    };

    return (
        <main className="App">
            <h1>My Tasks</h1>
            <AddList
                saveTask={handleSaveTask}
                habits={habits}
                formData={formData}
                setFormData={setFormData}
            />
            <List tasks={tasks} deleteTask={handleDeleteTask} />
        </main>
    );
};

export default App;
