import axios, { AxiosResponse } from "axios";
import { IHabit } from "./habit";

const baseUrl: string = "/v1";

export interface ITask {
    _id: string;
    notes: string;
    habit: IHabit;
    date: Date;
    createdAt?: string;
    updatedAt?: string;
}

export interface IForm {
    notes: string;
    habit: string;
    date: Date;
}

export const emptyForm: IForm = {
    notes: "",
    habit: "",
    date: new Date(),
};

export type ApiDataType = {
    message: string;
    status: string;
    tasks: ITask[];
    task?: ITask;
};

export const getTasks = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const tasks: AxiosResponse<ApiDataType> = await axios.get(
            baseUrl + "/tasks"
        );
        return tasks;
    } catch (error) {
        throw error;
    }
};

export const getTasksForDay = async (
    date: Date
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const tasks: AxiosResponse<ApiDataType> = await axios.get(
            `${baseUrl}/tasks/day/${date.getTime()}`
        );
        return tasks;
    } catch (error) {
        throw error;
    }
};

export const addTaskForDay = async (
    formData: ITask
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const task: Omit<ITask, "_id"> = {
            notes: formData.notes,
            habit: formData.habit,
            date: formData.date,
        };
        const saveTask: AxiosResponse<ApiDataType> = await axios.post(
            baseUrl + "/add-task/day",
            task
        );
        return saveTask;
    } catch (error) {
        throw error;
    }
};

export const addTask = async (
    formData: ITask
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const task: Omit<ITask, "_id"> = {
            notes: formData.notes,
            habit: formData.habit,
            date: formData.date,
        };
        const saveTask: AxiosResponse<ApiDataType> = await axios.post(
            baseUrl + "/add-task",
            task
        );
        return saveTask;
    } catch (error) {
        throw error;
    }
};

export const addTaskWithHabitName = async (
    formData: ITask | any
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const task = {
            notes: formData.notes,
            habit: formData.habit,
            date: formData.date,
        };
        const saveTask: AxiosResponse<ApiDataType> = await axios.post(
            baseUrl + "v1/add-task/habit-name",
            task
        );
        return saveTask;
    } catch (error) {
        throw error;
    }
};
export const updateTask = async (
    task: ITask
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const updatedTask: AxiosResponse<ApiDataType> = await axios.put(
            `${baseUrl}/edit-task/${task._id}`,
            task
        );
        return updatedTask;
    } catch (error) {
        throw error;
    }
};

export const deleteTask = async (
    _id: string
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedTask: AxiosResponse<ApiDataType> = await axios.delete(
            `${baseUrl}/delete-task/${_id}`
        );
        return deletedTask;
    } catch (error) {
        throw error;
    }
};
