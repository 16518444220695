import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { getTasks, ITask } from "../../data/task";
import { isSameDay } from "date-fns";

const CalendarComponent: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [tasks, setTasks] = useState<ITask[]>([]);

    const fetchTasks = (): void => {
        getTasks()
            .then(({ data: { tasks } }: ITask[] | any) => setTasks(tasks))
            .catch((err: Error) => console.log(err));
    };
    useEffect(() => {
        fetchTasks();
    }, []);

    const handleDateClick = (date: Date) => {
        setSelectedDate(date);
    };

    const getTaskCountForDate = (date: Date): number => {
        const entryDate = new Date(date);
        return tasks.filter((t) => {
            return isSameDay(new Date(t.date), entryDate);
        }).length;
    };

    const getCellColor = (date: Date): string => {
        const entryCount = getTaskCountForDate(date);
        if (entryCount === 0) {
            return "cell";
        }
        const colorClassName = `cell cell-color-${entryCount}`;
        return colorClassName;
    };

    const tileClassName = ({ date, view }: any) => {
        if (view === "month") {
            const cellColor = getCellColor(date);
            return `calendar-tile ${cellColor}`;
        }
        return "";
    };

    return (
        <div className="calendar">
            <Calendar
                tileClassName={tileClassName}
                onClickDay={handleDateClick}
                value={selectedDate || new Date()}
            />
            {selectedDate && (
                <div className="selected-date">
                    <h3>{selectedDate.toDateString()}</h3>
                    <ul>
                        {tasks
                            .filter(
                                (t) =>
                                    new Date(t.date).getTime() ===
                                    selectedDate.getTime()
                            )
                            .map((t) => (
                                <li key={t._id}>{t.notes}</li>
                            ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CalendarComponent;
