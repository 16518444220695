import axios, { AxiosResponse } from "axios";

const baseUrl: string = "/v1";

export interface IHabit {
    _id: string;
    name: string;
    description: string;
    createdAt?: string;
    updatedAt?: string;
}

export type HabitProps = {
    habit: IHabit;
};

export type ApiDataType = {
    message: string;
    habits: IHabit[];
    habit?: IHabit;
};

export const getHabits = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const habits: AxiosResponse<ApiDataType> = await axios.get(
            baseUrl + "/habits"
        );
        return habits;
    } catch (error) {
        throw error;
    }
};

export const addHabit = async (
    formData: IHabit
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const habit: Omit<IHabit, "_id"> = {
            name: formData.name,
            description: formData.description,
        };
        const saveHabit: AxiosResponse<ApiDataType> = await axios.post(
            baseUrl + "/add-habit",
            habit
        );
        return saveHabit;
    } catch (error) {
        throw error;
    }
};

export const updateHabit = async (
    habit: IHabit
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const updatedHabit: AxiosResponse<ApiDataType> = await axios.put(
            `${baseUrl}/edit-habit/${habit._id}`,
            habit
        );
        return updatedHabit;
    } catch (error) {
        throw error;
    }
};

export const deleteHabit = async (
    _id: string
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedHabit: AxiosResponse<ApiDataType> = await axios.delete(
            `${baseUrl}/delete-habit/${_id}`
        );
        return deletedHabit;
    } catch (error) {
        throw error;
    }
};
