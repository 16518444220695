import React, { useState, useEffect } from "react";
import { useEffectOnce } from "../../utils";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
    getTasksForDay,
    emptyForm,
    IForm,
    addTaskForDay,
    ITask,
} from "../../data/task";
import { getHabits, IHabit } from "../../data/habit";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ListProps = {
    tasks: ITask[];
    saveTask: (e: React.FormEvent, formData: IForm | any) => void;
    habits: IHabit[];
    date: Date;
};

type AddProps = {
    saveTask: (e: React.FormEvent, formData: IForm | any) => void;
    habit: IHabit;
    date: Date;
};

type ConnectedListProps = {
    date: string | undefined;
};

export const ConnectedList = ({ date }: ConnectedListProps) => {
    const [habits, setHabits] = useState<IHabit[]>([]);
    const [tasks, setTasks] = useState<ITask[]>([]);

    const [dateValue, setDateValue] = useState(new Date(Date.now()));

    const fetchTasksForDay = (date: Date): void => {
        getTasksForDay(date)
            .then(({ data }: ITask[] | any) => {
                console.log(data.tasks);
                setTasks(data.tasks);
            })
            .catch((err: Error) => console.log(err));
    };

    const fetchHabits = (): void => {
        getHabits()
            .then(({ data }: IHabit[] | any) => {
                setHabits(data.habits);
            })
            .catch((err: Error) => console.log(err));
    };

    const handleSaveTask = (e: React.FormEvent, formData: ITask): void => {
        e.preventDefault();
        console.log(formData);
        addTaskForDay(formData)
            .then(({ data, status }) => {
                if (status !== 201) {
                    throw new Error("Error! Task not saved");
                }
                setTasks(data.tasks);
            })
            .catch((err) => console.log(err));
    };

    useEffectOnce(() => {
        fetchHabits();
        fetchTasksForDay(dateValue);
    });

    useEffect(() => {
        if (date) {
            const newDate = new Date(+date);
            setDateValue(newDate);
            fetchTasksForDay(newDate);
        }
    }, [date]);

    return (
        <main className="add-task-day-list">
            <List
                date={dateValue}
                tasks={tasks}
                habits={habits}
                saveTask={handleSaveTask}
            />
        </main>
    );
};

const Component: React.FC = () => {
    const { date } = useParams();
    return <ConnectedList date={date} />;
};

const getTasksForHabit = (tasks: ITask[], habit: IHabit): ITask[] => {
    return tasks.filter((t) => t.habit._id === habit._id);
};

const List = ({ tasks, date, habits, saveTask }: ListProps) => {
    if (habits.length === 0) {
        return null;
    }
    const habitArray = habits.map((h) => {
        const tasksForHabit = getTasksForHabit(tasks, h);
        if (tasksForHabit.length > 0) {
            return {
                complete: 1,
                component: (
                    <div key={h._id} className="Card">
                        <div className="Card--text">
                            <h1 className={"line-through"}>{h.name}</h1>
                            <span className={"line-through"}>
                                {h.description}
                            </span>
                            <ul>
                                {tasksForHabit.map((t) => {
                                    return <li key={t._id}>{t.notes}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                ),
            };
        }
        return {
            complete: -1,
            component: (
                <Add saveTask={saveTask} habit={h} key={h._id} date={date} />
            ),
        };
    });
    return (
        <div>
            <h1>{date.toDateString()}</h1>
            {habitArray.sort((a) => a.complete).map((h) => h.component)}
        </div>
    );
};

const Add = ({ saveTask, habit, date }: AddProps) => {
    const [formData, setFormData] = useState<IForm>({
        ...emptyForm,
        date,
        habit: habit._id,
    });
    const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    return (
        <div>
            <h2>
                <label>{habit.name}</label>
            </h2>
            <form
                className="Form Card Card--add-task"
                onSubmit={(e) => saveTask(e, formData)}
            >
                <div className="Card--task-notes">
                    <input onChange={handleForm} type="text" id="notes" />
                </div>

                <button disabled={formData === undefined ? true : false}>
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </form>
        </div>
    );
};

export default Component;
