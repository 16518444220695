import React from "react";
import TaskItem from "./Item";
import { ITask } from "../../data/task";

type Props = {
    tasks: ITask[];
    deleteTask: (_id: string) => void;
};

const List = ({ tasks, deleteTask }: Props) => {
    if (tasks.length === 0) {
        return <></>;
    }
    return (
        <div>
            {tasks.map((task: ITask) => (
                <TaskItem key={task._id} deleteTask={deleteTask} task={task} />
            ))}
        </div>
    );
};

export default List;
