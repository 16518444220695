import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./index.css";
import Main from "./components/Main";
import Habits from "./components/Habits/Main";
import AddHabitList from "./components/Tasks/AddHabitList";
import Tasks from "./components/Tasks/Main";
import Calendar from "./components/Tasks/Calendar";
import CalendarEdit from "./components/Tasks/CalendarEdit";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Main />} />
                <Route path="/habits" element={<Habits />} />
                <Route path="/day/:date" element={<AddHabitList />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/calendar-edit" element={<CalendarEdit />} />
                <Route path="/calendar" element={<Calendar />} />
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));
