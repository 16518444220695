import React from "react";
import Habits from "./Habits/Main";
import Tasks from "./Tasks/Main";

export const Main = () => {
    return (
        <div>
            <Habits />
            <Tasks />
        </div>
    );
};

export default Main;
