import React, { useEffect, useState } from "react";
import HabitItem from "./Item";
import AddList from "./Add";
import { getHabits, addHabit, deleteHabit, IHabit } from "../../data/habit";

const App: React.FC = () => {
    const [habits, setHabits] = useState<IHabit[]>([]);

    useEffect(() => {
        fetchHabits();
    }, []);

    const fetchHabits = (): void => {
        getHabits()
            .then(({ data }: IHabit[] | any) => setHabits(data.habits))
            .catch((err: Error) => console.log(err));
    };

    const handleSaveHabit = (e: React.FormEvent, formData: IHabit): void => {
        e.preventDefault();
        addHabit(formData)
            .then(({ status, data }) => {
                if (status !== 201) {
                    throw new Error("Error! Habit not saved");
                }
                setHabits(data.habits);
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteHabit = (_id: string): void => {
        deleteHabit(_id)
            .then(({ status, data }) => {
                if (status !== 200) {
                    throw new Error("Error! Habit not deleted");
                }
                setHabits(data.habits);
            })
            .catch((err) => console.log(err));
    };

    return (
        <main className="App">
            <h1>My Habits</h1>
            <AddList saveHabit={handleSaveHabit} />
            {habits &&
                habits.map((habit: IHabit) => (
                    <HabitItem
                        key={habit._id}
                        deleteHabit={handleDeleteHabit}
                        habit={habit}
                    />
                ))}
        </main>
    );
};

export default App;
