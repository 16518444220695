import React from "react";
import { IHabit } from "../../data/habit";
import { IForm } from "../../data/task";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
type Props = {
    saveTask: (e: React.FormEvent, formData: IForm | any) => void;
    habits: IHabit[];
    formData: IForm;
    setFormData: (task: IForm) => void;
};

const AddTask = ({ saveTask, habits, formData, setFormData }: Props) => {
    const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    const changeHabit = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setFormData({
            ...formData,
            habit: e.target.value,
        });
    };

    const changeDate = (date: Date) => {
        setFormData({
            ...formData,
            date,
        });
    };

    return (
        <div>
            <form className="Form" onSubmit={(e) => saveTask(e, formData)}>
                <div className="Form--values">
                    <div className="Form--date">
                        <label>Date</label>
                        <DatePicker
                            selected={formData.date}
                            onChange={(date: Date) => changeDate(date)}
                        />
                    </div>

                    <div className="Form--habit">
                        <label htmlFor="habit">Habit</label>
                        <select value={formData.habit} onChange={changeHabit}>
                            {habits?.length &&
                                habits.map((habit, i) => {
                                    return (
                                        <option
                                            key={i}
                                            value={habit._id}
                                            label={habit.name}
                                        />
                                    );
                                })}
                        </select>
                    </div>
                    <div className="Form--notes">
                        <label htmlFor="Notes">Notes</label>
                        <input onChange={handleForm} type="text" id="notes" />
                    </div>
                </div>
                <button disabled={formData === undefined ? true : false}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </form>
        </div>
    );
};

export default AddTask;
