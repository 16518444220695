import React, { useState } from "react";
import type { IHabit } from "../../data/habit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type Props = {
    saveHabit: (e: React.FormEvent, formData: IHabit | any) => void;
};

const AddHabit = ({ saveHabit }: Props) => {
    const [formData, setFormData] = useState<IHabit | {}>();

    const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
        setFormData({
            ...formData,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    return (
        <div>
            <form className="Form" onSubmit={(e) => saveHabit(e, formData)}>
                <div>
                    <div>
                        <label htmlFor="name">Name</label>
                        <input onChange={handleForm} type="text" id="name" />
                    </div>
                    <div>
                        <label htmlFor="description">Description</label>
                        <input
                            onChange={handleForm}
                            type="text"
                            id="description"
                        />
                    </div>
                </div>
                <button disabled={formData === undefined ? true : false}>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </form>
        </div>
    );
};

export default AddHabit;
